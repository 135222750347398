import React from "react"
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import logo from "../images/logo-only.png"
import gameImage1 from "../images/smaa_portfolio_image.png"
import gameImage2 from "../images/stf_portfolio_image.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faMapPin,
    faPhone,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { Container /*, Row, Col*/ } from "reactstrap"
import "../../node_modules/text-image/dist/text-image.js"
import { Link } from "gatsby"
import Header from "../components/cybernate/header"
import "../css/index.css"

class IndexPage extends React.Component {
    constructor(props) {
        super(props)
        // Don't call this.setState() here!
        this.state = {
            emailImageSrc: null,
            mobileImageSrc: null,
            scrollTo: "games",
        }
    }

    componentDidMount() {
        // create new TextImage object with customize style
        var style = {
            font: "Sans-serif",
            align: "left",
            color: "#e4e4e4",
            size: 11,
        }

        var textImage = window.TextImage(style)

        // convert text message to image element
        var emailData = textImage.toDataURL(
            atob("Y2hyaXNAY3liZXJuYXRlLmNvbQ==")
        )
        var mobileData = textImage.toDataURL(atob("KzYxIDQwMCAzMzEgMzQz"))
        this.setState({
            emailImageSrc: emailData,
            mobileImageSrc: mobileData,
        })
    }

    render() {
        return (
            <Layout header={Header}>
                <SEO title="Cybernate" keywords={[`games`]} />

                <Container fluid className="hero main-section">
                    <img
                        className="mx-auto animated fadeInDown delay-05s"
                        src={logo}
                        alt="Cybernate Logo"
                    />
                    <h1 className="mx-3 mt-1 animated fadeIn delay-07s">
                        Welcome To Cybernate
                    </h1>
                    <p className="hero-text animated fadeInUp delay-1s">
                        I'm just one man, following my passion, creating worlds
                        of fun, for myself and others.
                    </p>
                </Container>

                <Container fluid className="main-section games">
                    <div>
                        <h2 id="games" className="animated fadeIn delay-1s">
                            Games
                        </h2>
                    </div>

                    <div className="d-flex justify-content-center flex-wrap animated fadeInUp delay-05s">
                        <div className="game-box">
                            <a href="https://store.steampowered.com/app/368680/Super_Mutant_Alien_Assault/">
                                <img
                                    src={gameImage1}
                                    alt="Super Mutant Alien Assault"
                                />
                            </a>
                            <h3>
                                <a href="https://store.steampowered.com/app/368680/Super_Mutant_Alien_Assault/">
                                    Super Mutant Alien Assault
                                </a>
                            </h3>
                            <p>
                                <span className="dimmed">Available on:</span>{" "}
                                PC, Xbox One, PS4, Vita, Switch
                            </p>
                        </div>
                        <div className="game-box">
                            <a href="https://apps.apple.com/us/app/slapstick-fighter/id1445642543">
                                <img src={gameImage2} alt="Slapstick Fighter" />
                            </a>
                            <h3>
                                <a href="https://apps.apple.com/us/app/slapstick-fighter/id1445642543">
                                    {" "}
                                    Slapstick Fighter
                                </a>
                            </h3>
                            <p>
                                <span className="dimmed">Available on:</span>{" "}
                                iOS, Android
                            </p>
                        </div>
                    </div>
                </Container>

                <Container fluid className="main-section contact">
                    <div>
                        <h2
                            id="contact"
                            className="wow animated fadeIn"
                            data-wow-offset="100"
                        >
                            Contact
                        </h2>
                    </div>
                    <div
                        className="contact-box mx-auto wow animated fadeInLeft"
                        data-wow-offset="100"
                    >
                        <div className="contact-info-box clearfix">
                            <h3>
                                <FontAwesomeIcon icon={faMapPin} /> Where:
                            </h3>
                            <span>Sydney, Australia</span>
                        </div>
                        <div className="contact-info-box clearfix">
                            <h3>
                                <FontAwesomeIcon icon={faPhone} /> Mobile:
                            </h3>
                            <span>
                                <img src={this.state.mobileImageSrc} alt="" />
                            </span>
                        </div>
                        <div className="contact-info-box clearfix">
                            <h3>
                                <FontAwesomeIcon icon={faEnvelope} /> Email:
                            </h3>
                            <span>
                                <img src={this.state.emailImageSrc} alt="" />
                            </span>
                        </div>
                        <div className="contact-info-box clearfix">
                            <h3>
                                <FontAwesomeIcon icon={faTwitter} /> Twitter:
                            </h3>
                            <span>
                                <a href="https://twitter.com/chris_suffern">
                                    @chris_suffern
                                </a>
                            </span>
                        </div>
                    </div>
                </Container>
            </Layout>
        )
    }
}

export default IndexPage
